import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { db } from './config/firebase-config';
import { collection, addDoc, serverTimestamp, query, where, getDocs, doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import ClassSelect from './ClassSelect';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
// import './styles/AddMemberModal.css';
import axios from 'axios';


const AddPaymentModal = ({ show, handleClose, memberId, isFromPaymentList = false, communityPlan }) => {
    const [selectedMemberId, setSelectedMemberId] = useState('');
    const [contactName, setContactName] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [selectedClassIds, setSelectedClassIds] = useState([]);
    const [showClassSelect, setShowClassSelect] = useState(false);
    const [error, setError] = useState(null);
    const [classes, setClasses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [stripeAccountId, setStripeAccountId] = useState(null);
    const [stripeCustomerId, setStripeCustomerId] = useState('');
    const [members, setMembers] = useState([]);
    const [memberData, setMemberData] = useState(null);
    const [isSendingSMS, setIsSendingSMS] = useState(false);
    const [communityId, setCommunityId] = useState(null);

    useEffect(() => {
        if (isFromPaymentList) {
            fetchMembers();
        } else if (memberId) {
            fetchMemberData(memberId);
        }
    }, [isFromPaymentList, memberId]);

    const fetchMemberData = async (id) => {
        try {
            const memberDoc = await getDoc(doc(db, 'members', id));
            if (memberDoc.exists()) {
                const data = memberDoc.data();
                setMemberData(data);
                setSelectedMemberId(id);
                setContactName(data.contact_name);
                setSelectedClassIds(data.classIds || []);
                setStripeCustomerId(data.stripeCustomerId || '');
            }
        } catch (error) {
            console.error('Error fetching member data:', error);
            setError('Error al cargar los datos del miembro.');
        }
        
    };

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                console.log('User authenticated:', user.uid);
                setCurrentUser(user);
                fetchStripeAccountId(user.uid);
                fetchCommunityId(user.uid); // Add this line
            } else {
                console.log('User signed out');
                setCurrentUser(null);
                setStripeAccountId(null);
                setCommunityId(null); // Add this line
            }
        });

        return () => unsubscribe();
    }, []);

    const fetchStripeAccountId = async (uid) => {
        console.log('Fetching Stripe account ID for user:', uid);
        try {
            const userDocRef = doc(db, 'users', uid);
            const userDocSnap = await getDoc(userDocRef);
            
            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                console.log('User data retrieved:', userData);
                if (userData.stripeAccountId) {
                    console.log('Stripe account ID found:', userData.stripeAccountId);
                    setStripeAccountId(userData.stripeAccountId);
                } else {
                    console.warn('Stripe account ID not found in user data');
                }
            } else {
                console.error('User document not found for UID:', uid);
            }
        } catch (error) {
            console.error('Error fetching Stripe account ID:', error);
        }
    };

    // Add this new function
    const fetchCommunityId = async (uid) => {
        try {
            const userDocRef = doc(db, 'users', uid);
            const userDocSnap = await getDoc(userDocRef);
            
            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                if (userData.communityId) {
                    console.log('Community ID found:', userData.communityId);
                    setCommunityId(userData.communityId);
                } else {
                    console.warn('Community ID not found in user data');
                }
            } else {
                console.error('User document not found for UID:', uid);
            }
        } catch (error) {
            console.error('Error fetching Community ID:', error);
        }
    };

    useEffect(() => {
        // Fetch classes
        const fetchClasses = async () => {
            setError(null);
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
                try {
                    const classesQuery = query(collection(db, 'classes'), where('createdBy', '==', user.uid));
                    const querySnapshot = await getDocs(classesQuery);
                    const classesData = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));
                    console.log('Fetched classes:', classesData);
                    setClasses(classesData);
                } catch (err) {
                    console.error('Error fetching classes:', err);
                    setError('Error al cargar las clases. Por favor, inténtalo de nuevo más tarde.');
                }
            }
        };
        fetchClasses();
    }, []);

    const fetchMembers = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            try {
                console.log('Current user ID:', user.uid);
                const membersQuery = query(collection(db, 'members'), where("createdBy", "==", user.uid));
                const querySnapshot = await getDocs(membersQuery);
                console.log('Query snapshot size:', querySnapshot.size);
                const membersData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                // Sort members alphabetically by contact_name
                membersData.sort((a, b) => a.contact_name.localeCompare(b.contact_name));
                console.log('Fetched and sorted members:', membersData);
                setMembers(membersData);
            } catch (err) {
                console.error('Error fetching members:', err);
                setError('Error al cargar los miembros. Por favor, inténtalo de nuevo más tarde.');
            }
        }
    };

    const handleMemberChange = (e) => {
        const selectedMember = members.find(member => member.id === e.target.value);
        if (selectedMember) {
            setSelectedMemberId(selectedMember.id);
            setContactName(selectedMember.contact_name);
            setSelectedClassIds(selectedMember.classIds || []);
            setStripeCustomerId(selectedMember.stripeCustomerId || '');
            setMemberData(selectedMember);
        }
    };

    const sendPaymentLink = async (memberId, paymentDocId) => {
        console.log('Attempting to send payment link');
        setIsSendingSMS(true);
        console.log('Current user:', currentUser?.uid);
        console.log('Stripe account ID:', stripeAccountId);
        console.log('Member ID:', memberId);

        if (!currentUser || !stripeAccountId || !memberData) {
            console.error('Missing required data');
            setError("Missing required data. Please try again.");
            setIsSendingSMS(false);
            return false;
        }

        if (!memberData) {
            console.error('Member data not found');
            setError("Member data not found. Please select a member and try again.");
            setIsSendingSMS(false);
            return false;
        }

        // Calculate total amount and get class details
        let totalAmount = 0;
        const itemsPaid = selectedClassIds.map(classId => {
            const classData = classes.find(c => c.id === classId);
            if (classData) {
                totalAmount += classData.cost;
                return {
                    id: classData.id,
                    name: classData.class_name,
                    cost: classData.cost
                };
            }
            return null;
        }).filter(Boolean);

        const dataToSend = {
            memberId: memberData.id,
            paymentDocId: paymentDocId,
            stripeAccountId: stripeAccountId,
            classIds: selectedClassIds,
            phoneNumber: memberData.number,
            memberName: memberData.contact_name,
            userId: currentUser.uid,
            customerId: memberData.stripeCustomerId,
            method: 'sms',
            itemsPaid: itemsPaid,
            totalAmount: totalAmount, 
            paymentDocId: paymentDocId
        };

        console.log("Sending payment link with the following data:", dataToSend);

        try {
            const response = await axios.post('/api/payments/send-payment-link', dataToSend);
            console.log('Full response from send-payment-link:', response.data);

            if (response.data.paymentUrl && response.data.sessionId) {
                const paymentRef = doc(db, 'payments', paymentDocId);
                try {
                    await updateDoc(paymentRef, {
                        stripeCheckoutUrl: response.data.paymentUrl,
                        stripeSessionId: response.data.sessionId,
                        status: 'Enviado'
                    });
                    console.log('Payment document updated successfully');
                } catch (updateError) {
                    console.error('Error updating payment document:', updateError);
                    setError(`Error updating payment document: ${updateError.message}`);
                }
            } else {
                console.warn('Stripe checkout URL or session ID not received in the response');
                setError('Failed to generate payment link. Please try again.');
                return false;
            }

            alert('Payment link sent successfully!');
            setIsSendingSMS(false);
            return true;
        } catch (error) {
            console.error('Error sending payment link:', error);
            setError(`Error sending payment link: ${error.message}`);
            setIsSendingSMS(false);
            return false;
        }
    };

    const updateMemberStatus = async (memberId, newStatus) => {
        if (!memberId) {
            console.error('No member ID provided');
            return;
        }

        const memberRef = doc(db, 'members', memberId);
        try {
            await updateDoc(memberRef, { status: newStatus });
            console.log(`Member ${memberId} status updated to ${newStatus}`);
        } catch (error) {
            console.error('Error updating member status:', error);
            throw error; // Rethrow the error to be caught in the calling function
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);
        const auth = getAuth();
        const user = auth.currentUser;

        console.log('Current user:', user ? user.uid : 'No user authenticated');
        console.log('Selected member ID:', selectedMemberId);
        console.log('Member data:', memberData);

        if (!user) {
            setError('User not authenticated. Please log in and try again.');
            setIsLoading(false);
            return;
        }

        if (!selectedMemberId || !memberData) {
            setError('Missing required information');
            setIsLoading(false);
            return;
        }

        if (!selectedMemberId) {
            setError('Please select a member');
            setIsLoading(false);
            return;
        }

        // Use memberData from state instead of prop
        if (!memberData) {
            setError('Selected member not found');
            setIsLoading(false);
            return;
        }

        if (!communityId) {
            setError('Community ID not found. Please try again.');
            setIsLoading(false);
            return;
        }

        console.log('Payment method:', paymentMethod); // Log the payment method

        let status = ['Transferencia', 'Efectivo', 'Tarjeta'].includes(paymentMethod) ? 'Pagado' : 'Pendiente';
        console.log('Initial status:', status); // Log the initial status

        let docRef;
        if (paymentMethod === 'Liga de pago') {
            console.log('Creating payment for Liga de Pago');
            const paymentData = {
                memberId: selectedMemberId,
                clientName: memberData.contact_name,
                paymentMethod: paymentMethod,
                itemsPaid: selectedClassIds.map(id => {
                    const selectedClass = classes.find(cls => cls.id === id);
                    return {
                        id: selectedClass.id,
                        name: selectedClass.class_name,
                        cost: Number(selectedClass.cost)
                    };
                }),
                totalAmount: totalAmount,
                date: serverTimestamp(),
                createdBy: user.uid,
                status: 'Pendiente',
                communityId: communityId, // Add this line
                metadata: {
                    userId: user.uid,
                    memberId: selectedMemberId
                },
                stripeSessionId: null,
                stripeCheckoutUrl: null
            };

            try {
                docRef = await addDoc(collection(db, 'payments'), paymentData);
                console.log("Payment added with ID: ", docRef.id);

                console.log('Attempting to send SMS');
                const smsSent = await sendPaymentLink(selectedMemberId, docRef.id);
                console.log('SMS sent result:', smsSent);

                if (!smsSent) {
                    throw new Error('Failed to send payment link');
                }
            } catch (error) {
                console.error('Error adding payment or sending link:', error);
                setError(`Error al crear el pago o enviar el enlace: ${error.message}`);
                setIsLoading(false);
                return;
            }
        } else {
            console.log('Creating payment for other method');
            const paymentData = {
                memberId: selectedMemberId,
                clientName: memberData.contact_name,
                paymentMethod: paymentMethod,
                itemsPaid: selectedClassIds.map(id => {
                    const selectedClass = classes.find(cls => cls.id === id);
                    return {
                        id: selectedClass.id,
                        name: selectedClass.class_name,
                        cost: Number(selectedClass.cost)
                    };
                }),
                totalAmount: totalAmount,
                date: serverTimestamp(),
                createdBy: user.uid, // Make sure this is set correctly
                status: status,
                communityId: communityId, // Add this line
                metadata: {
                    userId: user.uid,
                    memberId: selectedMemberId
                }
            };

            console.log('Payment data to be added:', paymentData);

            try {
                docRef = await addDoc(collection(db, 'payments'), paymentData);
                console.log("Payment added with ID: ", docRef.id);

                // Update member status if payment method is not 'Liga de pago'
                try {
                    await updateMemberStatus(selectedMemberId, 'Corriente');
                    console.log(`Member ${selectedMemberId} status updated to Corriente`);
                } catch (error) {
                    console.error('Error updating member status:', error);
                    // Optionally, you can handle the error here (e.g., show a notification to the user)
                }
            } catch (error) {
                console.error('Error saving payment or updating member:', error);
                console.error('Error code:', error.code);
                console.error('Error message:', error.message);
                console.error('Error details:', error.details);
                setError(`Error al procesar el pago o actualizar el miembro: ${error.message}`);
                setIsLoading(false);
                return;
            }
        }

        // Verify the final status and Stripe checkout URL
        const updatedDoc = await getDoc(doc(db, 'payments', docRef.id));
        const updatedData = updatedDoc.data();
        console.log('Updated payment document:', updatedData);
        console.log('Final payment status:', updatedData.status);
        console.log('Stripe checkout URL:', updatedData.stripeCheckoutUrl);

        // New code to update member's subscriptions
        try {
            console.log('Selected Class IDs:', selectedClassIds);
            console.log('Classes:', classes);

            const recurringClasses = selectedClassIds
                .map(id => {
                    const cls = classes.find(c => c.id === id);
                    if (!cls) {
                        console.warn(`Class with id ${id} not found`);
                    }
                    return cls;
                })
                .filter(cls => cls && cls.cadenceType === 'recurring');

            console.log('Recurring Classes:', recurringClasses);

            if (recurringClasses.length > 0 && selectedMemberId) {
                console.log('Updating member subscriptions');
                console.log('Selected member ID:', selectedMemberId);
                const memberRef = doc(db, 'members', selectedMemberId);
                
                const activeSuscriptions = recurringClasses.map(cls => ({
                    classId: cls.id,
                    className: cls.class_name,
                    cost: cls.cost,
                    cadence: cls.cadence
                }));

                console.log('Active Subscriptions to be added:', activeSuscriptions);

                await updateDoc(memberRef, {
                    activeSuscriptions: activeSuscriptions,
                    clientSubscribed: true
                });
                console.log("Member subscriptions updated successfully");
            } else {
                console.log('No recurring classes or missing member ID, skipping member update');
            }
        } catch (memberUpdateError) {
            console.error('Error updating member:', memberUpdateError);
            console.error('Error details:', memberUpdateError.message);
            // Optionally set an error state or handle the error as needed
        }

        handleClose(updatedData); // Pass the updated payment data
    };

    const handleClassChange = (e) => {
        const { value, checked } = e.target;
        setSelectedClassIds(prev =>
            checked ? [...prev, value] : prev.filter(id => id !== value)
        );
    };

    const toggleClassSelect = () => {
        setShowClassSelect(prevState => !prevState);
    };

    // Calculate total amount for display
    const totalAmount = selectedClassIds.reduce((total, classId) => {
        const selectedClass = classes.find(cls => cls.id === classId);
        return total + (selectedClass ? Number(selectedClass.cost) : 0);
    }, 0);

    // Add this function to get the appropriate button text
    const getButtonText = () => {
        if (isLoading || isSendingSMS) {
            return paymentMethod === 'Liga de Pago' ? 'Creando pago y enviando SMS...' : 'Creando pago...';
        }
        return paymentMethod === 'Liga de Pago' ? 'Crear y enviar link de pago' : 'Crear pago';
    };

    console.log('Current members state:', members); // Add this line

    const paymentMethods = ['Efectivo', 'Tarjeta', 'Transferencia'];
    if (communityPlan && communityPlan.toLowerCase() === 'cobranza') {
        paymentMethods.push('Liga de pago');
    }

    return (
        <Modal 
            show={show} 
            onHide={handleClose} 
            className="fade" 
            dialogClassName="modal-dialog modal-fullscreen-sm-down payment-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Crear pago</Modal.Title>
            </Modal.Header>

            <Modal.Body className="modal-body-scroll">
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    {isFromPaymentList && (
                        <Form.Group className="mb-3">
                            <Form.Label>Seleccionar miembro</Form.Label>
                            <Form.Select
                                value={selectedMemberId}
                                onChange={handleMemberChange}
                                required
                            >
                                <option value="">Selecciona un miembro...</option>
                                {members.length > 0 ? (
                                    members.map(member => (
                                        <option key={member.id} value={member.id}>
                                            {member.contact_name}
                                        </option>
                                    ))
                                ) : (
                                    <option disabled>Cargando miembros...</option>
                                )}
                            </Form.Select>
                        </Form.Group>
                    )}

                    {!isFromPaymentList && (
                        <Form.Group className="mb-3">
                            <Form.Label>Nombre del cliente</Form.Label>
                            <Form.Control
                                type="text"
                                value={contactName}
                                readOnly
                            />
                        </Form.Group>
                    )}

                    <Form.Group className="mb-3">
                        <Form.Label>Método de pago</Form.Label>
                        <Form.Control
                            as="select"
                            value={paymentMethod}
                            onChange={e => setPaymentMethod(e.target.value)}
                            required
                        >
                            <option value="">Selecciona una opción...</option>
                            {paymentMethods.map(method => (
                                <option key={method} value={method}>{method}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Agregar productos</Form.Label>
                        <Form.Control
                            type="text"
                            value="Selecciona uno o más productos..."
                            onClick={toggleClassSelect}
                            readOnly
                            placeholder="Selecciona una o más opciones..."
                        />
                        {showClassSelect && classes && (
                            <ClassSelect 
                                classes={classes}
                                selectedClassIds={selectedClassIds}
                                handleClassChange={handleClassChange}
                            />
                        )}
                    </Form.Group>
                </Form>
            </Modal.Body>

            <div className="selected-classes-summary">
                {selectedClassIds.map(classId => {
                    const selectedClass = classes.find(cls => cls.id === classId);
                    return (
                        <div key={classId} className="selected-class-item">
                            <span>{selectedClass.class_name}</span>
                            <span>${selectedClass.cost} {selectedClass.cadence}</span>
                        </div>
                    );
                })}
                <div className="total-amount">
                    <span>Total</span>
                    <span>${totalAmount.toFixed(2)} mensual</span>
                </div>
            </div>

            <Modal.Footer>
                <Button 
                    className="modal-button btn btn-primary w-100" 
                    variant="primary" 
                    type="submit" 
                    onClick={handleSubmit}
                    disabled={isLoading || isSendingSMS}
                >
                    {getButtonText()}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddPaymentModal;